import moment from 'moment';
import { regEx, patternType } from './constant';
import CryptoJS from 'crypto-js';
const encryptionSecretKey = process.env.NEXT_PUBLIC_ENC_KEY;

export function getOSInfo() {
  let name = "Not known",
    version = "Not known";
  if (navigator.appVersion.indexOf("Win") !== -1) name =
    "Windows";
  else if (navigator.appVersion.indexOf("Mac") !== -1) name =
    "Mac";
  else if (navigator.appVersion.indexOf("X11") !== -1) name =
    "UNIX";
  else if (navigator.appVersion.indexOf("Linux") !== -1) name =
    "Linux";

  version = parseFloat(navigator.appVersion).toString()
  return { name, version }
}

export function getBrowserInfo() {
  let userAgent = navigator.userAgent;
  let name = "Not known";
  if (userAgent.indexOf('Chrome') !== -1) {
    name = 'Chrome'
  }
  else if (userAgent.indexOf('Opera') !== -1) {
    name = 'Opera'
  }
  else if (userAgent.indexOf('Safari') !== -1) {
    name = 'Safari'
  }
  else if (userAgent.indexOf('Firefox') !== -1) {
    name = 'Firefox'
  }
  return { name }
}

export function getLocalIp(listnerFunc) { //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  let myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
  let pc = new myPeerConnection({
    iceServers: []
  }),
    noop = function () { },
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;


  function iterateIP(ip) {
    if (!localIPs[ip]) listnerFunc(ip);
    localIPs[ip] = true;
  }
  // onNewIP
  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer().then(function (sdp) {
    sdp.sdp.split('\n').forEach(function (line) {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });

    pc.setLocalDescription(sdp, noop, noop);
  }).catch(function (reason) {
    // An error occurred, so handle the failure to connect
  });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

export function getPublicIp(listnerFunc) {
  return fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      listnerFunc(data.ip)
    }
    )
}

export function getUserMachineIp() {
  return new Promise((resolve, reject) => {
    // getLocalIp(resolve)
    getPublicIp(resolve)
  })
}

export function isValidPanNumber(panNumber) {
  const panRegex = RegExp(regEx.panRegex);
  return panRegex.test(panNumber)
}

export function isValidUserId(userId) {
  const panRegex = RegExp(regEx.userIdRegex);
  return panRegex.test(userId)
}

export function isValidMobileNo(mobileNo) {
  const panRegex = RegExp(regEx.mobileNumber);
  return panRegex.test(mobileNo)
}

export function isValidNumber(number) {
  const numberRegex = RegExp(regEx.number);
  return numberRegex.test(number)
}

export function isValidEmail(email) {
  const panRegex = RegExp(regEx.email);
  return panRegex.test(email)
}

export function isValidOtp(otp) {
  const otpRegex = RegExp(regEx.otpRegex);
  return otpRegex.test(otp);
}

export function removeDuplicates(array) {
  return array.filter((a, b) => array.indexOf(a) === b)
}

export function isValidAlphaNumeric(value) {
  const alphaNumericRegex = RegExp(regEx.alphaNumeric);
  return alphaNumericRegex.test(value);
}
export function numberText(data) {
  let check = RegExp(regEx.number);
  return check.test(data)
}

export function floatNumberTex(data) {
  let check = RegExp(regEx.numberWithSingleDecimal);
  return check.test(data);
  // ^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$

}

export function isValidAlphabates(value) {
  const alphaRegex = RegExp(regEx.alphabates);
  return alphaRegex.test(value);
}

export function isValidAlphabatesWithSpace(value) {
  const alphaRegex = RegExp(regEx.alphabatesWithSpace);
  return alphaRegex.test(value);
}

export function isValidInteger(value) {
  const alphaRegex = RegExp(regEx.alphabates);
  return alphaRegex.test(value);
}

export function isValidFloat(value) {
  const floatRegex = RegExp(regEx.float);
  return floatRegex.test(value);
}

export function isValidPattern(value, pattern) {
  switch (pattern) {
    case patternType.alphaNumeric:
      return isValidAlphaNumeric(value)
    case patternType.alphabates:
      return isValidAlphabates(value)
    case patternType.numeric:
      return isValidNumber(value)
    // case patternType.float:
    //     return isValidFloat(value)
    default:
      return true
  }
}

export const delay = (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  })
}

export const amountToWords = (num) => {
  var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
  return str;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const calculateFrequency = (startDate, endDate, frequency) => {
  let sDate = new Date(startDate); //, 'YYYY-MM-DD').format('DD-MM-YYYY');
  let eDate = new Date(endDate);
  let instCount = "";
  if (frequency.toLowerCase() === "monthly") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "months");
    instCount = diff
  }
  if (frequency.toLowerCase() === "yearly") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "years");
    instCount = diff
  }
  if (frequency.toLowerCase() === "daily") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "days");
    instCount = diff
  }
  if (frequency.toLowerCase() === "weekly") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "weeks");
    instCount = diff
  }
  if (frequency.toLowerCase() === "fortnightly") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "weeks");
    instCount = diff / 2
  }
  if (frequency.toLowerCase() === "quarterly") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "years");
    instCount = diff * 4
  }
  if (frequency.toLowerCase() === "half yearly") {
    const diff = moment(eDate, "DD-MM-YYYY").diff(sDate, "years");
    instCount = diff * 2
  }
  return instCount;
}

export const maskAccNumber = (data) => {
  // data should come in string;
  data = data.replace(/\d(?=\d{4})/g, "*")
  return data;
}

export const checkLogOut = (data) => {
  if (data.hasOwnProperty('Code') && data.Code === 401) {
    return true;
  } else {
    return false;
  }
}

export const roundOff = (num) => {
  if (num) {
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    return with2Decimals;
  }
  return num;
}

export const setEncryptedItem = (key, value) => {
  try {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), encryptionSecretKey).toString();
    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error("Error storing encrypted data in localStorage:", error);
  }
};

export const getDecryptedItem = (key) => {
  try {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;
    const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, encryptionSecretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedValue);
  } catch (error) {
    console.error("Error retrieving or decrypting data from localStorage:", error);
    return null;
  }
};
