/* LOCAL AND DXTECH SERVER CONFIG */
// let local = "http://dxtechsol.com:3000/api/"; // process.env.NEXT_PUBLIC_APIURL;
//  let baseAPI = "http://dxtechsol.com:8080/api/v1/"; // process.env.NEXT_PUBLIC_MFAPIURL;
//  let BaseURL = "http://122.184.137.14/"; // process.env.NEXT_PUBLIC_BASEURL;
//  let BaseMiddlewear = "http://dxtechsol.com:8080/api/v1/"; // process.env.NEXT_PUBLIC_MID;
//"https://pms.moamc.com/pms/api/v1/"
/* MO SERVER CONFIG */
let local = process.env.NEXT_PUBLIC_APIURL;
let baseAPI = process.env.NEXT_PUBLIC_MFAPIURL;
let BaseURL = process.env.NEXT_PUBLIC_BASEURL;
let BaseMiddlewear = process.env.NEXT_PUBLIC_MID;
let PMSAPIURL = process.env.NEXT_PUBLIC_BASEURL_API;
export const endpoints = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    app: {
        //init: `${baseAPI}init`
        init: `${BaseURL}initApi/api/Init`,
        initApi: `/api/init`,
    },
    user: {
        getUser: '/users'
    },
    login: {
        generateOtp: '/login/generateOtp',
        verifyOtp: '/login/authenticateUser',
        logout: '/login/logout',
        common: `${baseAPI}login/common`,
        kycCheck: `${local}kyc`,
        generateOTP: `${local}otp`,
        clientType: `${local}clientType`,
        authenticateUser: `${local}login`
    },
    additionalPurchase: {
        getFolioList: '/additionalPurchase/folioList',
    },
    preLogin: {
        internationalBusiness: `${baseAPI}getIntBusiness`,
        getAIFDetails: `${baseAPI}getAIFDetails`,
        aboutUs: `${BaseMiddlewear}Aboutus`,
        faq: `${BaseMiddlewear}getFAQ`,
        NavDetail: `${baseAPI}NAVDetails`,
        HistoricalFilter: `${baseAPI}HistoricalFilter`,
        MFHome: `${baseAPI}MFHome`,
        passiveHome: `${baseAPI}PassiveFundHome`,
        HomePage: `${baseAPI}HomePage`,
        fundDetail: `${baseAPI}FundDetail`,
        FundCategory: `${baseAPI}FundCategory`,
        FundCategoryNew: `/pms/api/v1/FundCategoryNew`,
        STPCategory: `${baseAPI}SchemesForSTP`,
        GetFundsForFilter: `${baseAPI}GetFundsForFilter`,
        CompareFunds: `${baseAPI}CompareFunds`,
        download: `${baseAPI}getDownloads`,
        SearchDownloads: `${baseAPI}SearchDownloads`,
        ContactUs: `${baseAPI}ContactUs`,
        enquiry: `${baseAPI}enquiry`,
        perforamceGraph: `${baseAPI}PerformanceGraph`,
        perforamceGraphNew: `/pms/api/v1/PerformanceGraphNew`,
        PMSCategory: `${baseAPI}PMSFundCategory`,
        PMSGraphData: `/pms/api/v1/PMSGraphData`,
        PMSGraphDataTemp: `${PMSAPIURL}api/v1/PMSGraphData`,
        PMSFundDetail: `${BaseMiddlewear}PMSFundDetail`,
        getSchemesForSEO: `${baseAPI}getSchemesForSEO`,
        getTotalExpratio: `${baseAPI}getTotalExpratio`,
        PMSHomePage: `${BaseMiddlewear}PMSHomePage`,
        SEODetails: `${baseAPI}SEODetails`,
    },
    ob: {
        masters: `${baseAPI}ob/getMasters`,
        common: `${baseAPI}ob/common`,
        ifscBankDet: `${baseAPI}ob/getBankFromIFSC`
    },
    trans: {
        common: `${baseAPI}trans/common`,
        commonGet: `${local}user`,
        foliolist: `${local}foliolist`,
        kycCheck: `${local}kyc`,
        CartDetails: `${local}CartDetails`

    },
    extra: {
        GetSchemeDetForSIPDashboard: `${baseAPI}/GetSchemeDetForSIPDashboard`,
        getSchemeName: `${baseAPI}/getSchemeName`,
    },
    checkCookies: `/pms/api/v1/chklogin`,

}