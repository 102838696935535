import Api from '.';
import { endpoints } from './endpoints';

export default class AppApi extends Api {

    init(data) {
        let url = this.buildUrl(endpoints.app.init, "full")
        console.info("endpoints", endpoints)
        console.info("init url", url);

        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }

    initApi(data) {
        let url = this.buildUrl(endpoints.app.initApi, "full")
        return this.fetchNormal(url, "POST", JSON.stringify(data)).then(response => response)
    }

    initFolioList() {
        let url = this.buildUrl(endpoints.additionalPurchase.getFolioList)
        console.log('folio list url', url)
        return this.fetch(url, "GET").then(response => response)
    }

}
